import image1 from "../assets/t-image4.jpg";
import image2 from "../assets/image2a.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      `Joseph Lumbahe, co-Founder of CaliVision and IT Personality in South Africa, is the AI aficionado behind CaliVision. He harnesses 
      his extensive expertise in conversational AI and entrepreneurship to revolutionize the fitness industry. His knack 
      for blending business methodologies with advanced AI strategies ensures CaliVision is at the forefront of 
      workout technology, making advanced workout analytics accessible to fitness enthusiasts.`,
    name: 'Joseph Lumbahe',
    status : 'Co-Founder' 
  },
  {
    image: image2,
    review: `Shaun Johnson, co-Founder of CaliVision, a powerhouse in mechatronics and Computer Vision with a personal passion for calisthenics, drives CaliVision’s 
    innovation to new heights. His ingenuity lies in optimizing system performance to deliver a seamless and capable vision AI ensuring that users receive unparalleled insights 
    into their workout form and performance, embodying the fusion of tech and fitness.`,
    name: 'Shaun Johnson',
    status: 'Co-Founder'
  },
  {
    image : image3,
    review:`Amaan Vally, co-Founder of CaliVision, brings to life the innovative engineering and dynamic interplays of the product. Masterfully blending his expertise
    in embedded systems and IoT to elevate the user's experience and redefine fitness technology. His expertise in 3D motion capture along with his foresight in melding
    AI with sports technology transforms CaliVision into an intuitive and holistic fitness partner for every user.`,
    name: 'Amaan Vally',
    status: "Co-Founder"
  }
];
