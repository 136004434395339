import React from "react";
import "./Footer.css";
import Facebook from "../../assets/facebook-6.png";
import Instagram from "../../assets/instagram.png";
import LinkedIn from "../../assets/linkedin.png";
import Logo from '../../assets/logo3.png'

function redirectTo(url) {
  window.location.href = url;
}

const Footer = () => {
  return (
    <div className="Footer-container">
      <hr />
      <div className="footer">
        <div className="social-links">
          <img src={Facebook} alt="" onClick={() => redirectTo('https://www.facebook.com/profile.php?id=61556054210747&mibextid=LQQJ4d')}/>
          <img src={Instagram} alt="" />
          <img src={LinkedIn} alt="" onClick={() => redirectTo('https://www.linkedin.com/company/calivision-sa')}/>
        </div>
        <div className="logo-f">
            <img src={Logo} alt="" />
        </div>
      </div>
      <div className="blur blur-f"></div>
      <div className="blur blur-f"></div>
    </div>
  );
};

export default Footer;
